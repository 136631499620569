@import '../serenity/sass/layout/variables';
@import './variables';

.service-dropdown {
    .p-dropdown {
        width: 350px !important;

        .p-dropdown-filter-container {
            > input {
                width: 100% !important;
                color: $primaryTextColor !important;

                &::placeholder {
                    color: $primaryTextColor !important;
                }
            }

            .p-dropdown-filter-icon {
                margin-top: 4px !important;
            }
        }

        .p-dropdown-trigger {
            color: $primaryTextColor;
        }
    }

    @media (max-width: $mobileBreakpoint) {
        .p-dropdown {
            width: 100% !important;

            .p-dropdown-filter-container {
                > input {
                    width: 100% !important;
                    color: $primaryTextColor !important;
                    margin-left: 0 !important;

                    &::placeholder {
                        color: $primaryTextColor !important;
                    }
                }

                .p-dropdown-filter-icon {
                    margin-top: 0 !important;
                }
            }
        }
    }
}
