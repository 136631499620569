@import '../variables';

.add-custom-field-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &.hasCustomFields {
        padding-top: 10px;
    }
}

.delete-custom-field-button-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .p-button {
        background-color: $inactiveColor !important;
    }
}

.edit-option-input-wrapper {
    padding-top: 4px !important;
    padding-bottom: 18px !important;

    .edit-option-input-group {
        //margin-right from p-button class
        margin-right: 0.25em;

        padding-bottom: 14px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .edit-option-inputs {
            width: 100%;

            .option-input {
                display: inline-block;

                input {
                    width: 100% !important;
                }

                &:not(:last-child) {
                    margin-right: 35px;
                }
            }
        }
    }

    .add-option-button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .delete-option-button {
        background-color: $inactiveColor !important;
    }
}
