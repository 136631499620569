$primaryColor: #607d8b;
$primaryDarkColor: #455a64;
$primaryLightColor: #90a4ae;
$primaryLightestColor: #eceff1;
$primaryTextColor: #ffffff;
$accentTextColor: #ffffff;

$highlightTableRowColor: rgba(0, 0, 0, 0.3);

$successMessageColor: #4cb14d;
$infoMessageColor: #2098f1;
$errorMessageColor: #b70806;
$warnMessageColor: #ff9800;
$messageTextColor: black;

$activeColor: #26c281;
$inactiveColor: #cb5a5e;
$waitingColor: #ffdf3b;
