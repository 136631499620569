@import '../variables';

.id-col,
.name-col {
    width: 200px;
}

.logo-col {
    width: 100px !important;
}

.active-col,
.sessionLog-col,
.chatLog-col {
    width: 50px;
}

.search-field {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 20px 0;

    .p-inputtext {
        width: 200px;
    }
}

.logo {
    max-width: 80px;
}

.icon {
    &.active {
        color: $activeColor !important;
    }

    &.inactive {
        color: $inactiveColor !important;
    }
}
