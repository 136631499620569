@import '../variables';
@import '../variables_adiacom';

/* Sidebar */
$sidebarLogoBgColor: rgba(36, 50, 59, 0.5);
$sidebarBgColor: #202d35;
$darkSidebarBgColor: #202d35;
$horizontalMenuBgColor: #080a10;
$horizontalDarkMenuBgColor: #0a1118;

/* Primary */
$primaryColor: $primaryColor;
$primaryDarkColor: $primaryDarkColor;
$primaryLightColor: $primaryLightColor;
$primaryLightestColor: $primaryLightestColor;
$primaryTextColor: $primaryTextColor;

/* Accent */
$accentColor: $themeColor;
$accentLightColor: $themeColorLight;
$accentTextColor: $accentTextColor;

/* Topbar */
$topbarTextColor: $primaryLightestColor;
$topbarIconColor: $primaryLightestColor;

/* Submenu */
$submenuBgColor: rgba(255, 255, 255, 0.2);
$darkSubmenuBgColor: rgba(255, 255, 255, 0.2);
$horizontalSubmenuBgColor: #2b3d4c;
$horizontalDarkSubmenuBgColor: #0e161e;
$horizontalSubmenuitemHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverTextColor: #ffffff;
$horizontalMenuActiveTextColor: #ffffff;
$horizontalMenuActiveHoverTextColor: #ffffff;
$horizontalDarkMenuActiveTextColor: #ffffff;
$horizontalDarkMenuActiveHoverTextColor: #ffffff;

/* Default MenuItem */
$menuitemTextColor: #ffffff;
$menuitemIconTextColor: #ffffff;

/* Hover MenuItem */
$menuitemHoverBgColor: #rgba(255, 255, 255, 0.4);
$menuitemHoverTextColor: #ffffff;
$menuitemHoverIconTextColor: #ffffff;

/* Active MenuItem */
$menuitemActiveBgColor: $themeColor;
$menuitemActiveTextColor: $primaryTextColor;
$menuitemActiveIconTextColor: $primaryTextColor;
$subMenuitemActiveTextColor: $themeColorLight;
$subMenuitemActiveIconTextColor: $themeColorLight;

/* Dark Default MenuItem */
$darkMenuitemTextColor: #dee0e3;
$darkMenuitemIconTextColor: #dee0e3;

/* Dark Hover MenuItem */
$darkMenuitemHoverBgColor: #rgba(255, 255, 255, 0.4);
$darkMenuitemHoverTextColor: #ffffff;
$darkMenuitemHoverIconTextColor: #ffffff;

/* Dark Active MenuItem */
$darkMenuitemActiveBgColor: $themeColor;
$darkMenuitemActiveTextColor: $primaryTextColor;
$darkMenuitemActiveIconTextColor: $primaryTextColor;
$darksubMenuitemActiveTextColor: $themeColorLight;
$darksubMenuitemActiveIconTextColor: $themeColorLight;

@import '../../serenity/sass/layout/layout';

/*
  adiacom serenity customization
 */

// menu mode toggle icon
.sidebar-anchor {
    margin-top: 15px !important;
}

// override for account circle right
.profile-name {
    display: inline-block !important;
    margin-left: 0 !important;
    margin-right: 6px !important;
    vertical-align: middle !important;
    margin-top: -24px !important;
}

@media (max-width: $mobileBreakpoint) {
    .profile-name {
        display: inline-block !important;
        margin-left: 0 !important;
        vertical-align: middle !important;
        margin-top: -0 !important;
    }
}

// dropdown desktop mode
@media (max-width: $mobileBreakpoint) {
    .topbar-desktop-dropdown {
        display: none;
    }
}

.topbar-desktop-dropdown-arrow {
    margin-left: -8px;
}

// icon for mobile menu
@media (min-width: $mobileBreakpoint) {
    .topbar-icon.mobile-only {
        display: none;
    }
}

// transparent if submenu item but no subitem selected
.open-submenu-inactive-item {
    > a {
        background-color: $themeColorTransparent !important;
    }
}

// fix active menu item border radius
.layout-wrapper .layout-sidebar .layout-menu > li.active-menuitem a {
    border-radius: 6px !important;
}
.layout-wrapper
    .layout-sidebar
    .layout-menu
    > li.active-menuitem.open-submenu
    a {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

// fix menu close on mobile mode
.layout-main-mask {
    height: 100vh !important;
}

.layout-breadcrumb {
    padding-top: 0 !important;
}

.topbar-padding {
    padding-top: 64px;
}

//exception panel styling
.layout-content > .exception-body {
    background-image: none !important;
}

.layout-content > .exception-body > .exception-panel {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: initial !important;
}

.layout-content > .exception-body > .exception-panel > .exception-code {
    background-color: $themeColor !important;
}

.layout-content
    > .exception-body
    > .exception-panel
    > .exception-detail
    > .exception-icon {
    background-color: $themeColor !important;
}

// fix dialog opaque with primereact 4.0.0 and serenity 3.0.2
.p-dialog-mask {
    opacity: 1 !important;
    background-color: rgba(66, 66, 66, 0.8) !important;
}
