@import '../variables';

.date-col,
.start-col,
.duration-col {
    width: 75px;
}

.adviser-col {
    width: 200px;
}

.participants-col,
.recordings-col {
    width: 50px;
}

.icon {
    &.active {
        color: $activeColor !important;
    }

    &.inactive {
        color: $inactiveColor !important;
    }
}

.p-datatable-header {
    padding: 0.625em 1em 0.3em 1em !important;
    height: 40px;

    .filter-selector {
        float: left;

        .p-multiselect-items-wrapper {
            color: #212121;
        }

        .p-multiselect {
            width: fit-content;
            max-width: 50%;
            min-width: 75px;
        }
    }

    .export-data-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 10px;
        background-color: $primaryDarkColor;
    }
}

.scroll-panel {
    width: 100% !important;
    height: 300px !important;
    border: none !important;

    .scroll-panel-content {
        > div {
            padding-right: unset;
            padding-bottom: unset;
        }
    }
}

.log-entry {
    margin-bottom: 5px;
    padding: 4px;
    background-color: #f4f4f4;

    &:last-child {
        margin-bottom: 0;
    }

    .log-entry-timestamp {
        font-style: italic;
    }

    .log-entry-adviser {
        position: relative;
        top: 3px;
        margin: 0 2px 0 5px;
        font-size: 1.2em;
    }

    .log-entry-user {
        margin-left: 5px;
    }

    .log-entry-username {
        font-weight: 600;
    }

    .log-entry-message {
        margin-left: 2px;
    }
}

.info-table {
    table-layout: auto !important;

    tbody {
        border: none !important;
    }

    .column-header {
        display: none;
    }

    .column-body {
        border-left: none !important;
        border-right: none !important;
        text-align: left;

        &.row-header {
            font-weight: bold;

            &.meeting-info {
                width: 160px !important;
            }

            &.participant-info {
                width: 50px !important;
            }
        }
    }
}

.session-export-dialog {
    .p-dialog-content{
        overflow-y: unset;
    }

    .calendar {
        max-width: 125px;
    }

    .max-data-message {
        font-size: smaller;
        font-style: italic;
    }

    .export-type{
        padding-top: 15px;

        .export-type-dropdown{
            width: 100px;
            margin-left: 10px;

        }
    }
}

.recordings-download-dialog {
    .download-recordings-progressbar {
        margin: 10px 0 0 0;
    }
}

.hidden {
    display: none !important;
}
